import { createStyles } from '@mantine/core'
import checkedIcon from '@/assets/images/icons/tariff-checked.svg'

export default createStyles((theme) => {
  return {
    card: {},

    cardHighlight: {
      ['&[data-with-border]']: {
        borderColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      },
    },

    header: {
      minHeight: '90px',
    },

    list: {
      ['ul']: {
        listStyleType: 'none',
        paddingLeft: '24px',

        ['li']: {
          position: 'relative',
          display: 'block',
          margin: '8px 0',

          [':before']: {
            content: '""',
            position: 'absolute',
            top: '5px',
            left: '-24px',
            width: '17px',
            height: '26px',
            backgroundImage: `url(${checkedIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '17px 16px',
            zIndex: 10,
          },
        },
      },
    },
  }
})
