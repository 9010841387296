import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsMoon, BsSun } from 'react-icons/bs'
import { Switch, SwitchProps, useMantineColorScheme, useMantineTheme } from '@mantine/core'

interface IProps {
  className?: string
  switchProps?: SwitchProps
  withLabel?: boolean
}

export const ColorSchemeToggle: React.FC<IProps> = ({
  className,
  switchProps,
  withLabel = false,
  ...props
}) => {
  const { t } = useTranslation()

  const theme = useMantineTheme()

  const { colorScheme, toggleColorScheme } = useMantineColorScheme()

  const dark = colorScheme === 'dark'

  return (
    <Switch
      checked={dark}
      onChange={(event) => toggleColorScheme()}
      size="md"
      color={'dark'}
      onLabel={<BsSun size={16} color={theme.white} />}
      offLabel={<BsMoon size={16} color={theme.colors['dark'][theme.fn.primaryShade()]} />}
      label={withLabel ? t('color-switch-to', { scheme: dark ? t('light') : t('dark') }) : null}
      styles={{
        thumb: {
          backgroundColor: dark ? theme.colors['dark'][5] : theme.white,
        },
      }}
      {...switchProps}
    />
  )
}
