import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const dark = theme.colorScheme === 'dark'

  return {
    section: {
      backgroundColor: dark ? theme.colors.dark[7] : theme.white,
    },

    wrapper: {
      position: 'relative',
      paddingTop: 120,
      paddingBottom: 80,

      [theme.fn.smallerThan('sm')]: {
        paddingTop: 80,
        paddingBottom: 60,
      },
    },

    inner: {
      position: 'relative',
      zIndex: 1,
    },

    title: {
      textAlign: 'center',
      fontWeight: 800,
      fontSize: 40,
      letterSpacing: -1,
      marginBottom: theme.spacing.xs,

      [theme.fn.smallerThan('xs')]: {
        fontSize: 28,
        textAlign: 'left',
      },
    },

    description: {
      textAlign: 'center',

      [theme.fn.smallerThan('xs')]: {
        textAlign: 'left',
        fontSize: theme.fontSizes.md,
      },
    },

    controls: {
      marginTop: theme.spacing.lg,
      display: 'flex',
      justifyContent: 'center',

      [theme.fn.smallerThan('xs')]: {
        flexDirection: 'column',
      },
    },
  }
})
