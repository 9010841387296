import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Text, Title } from '@mantine/core'
import { useBilling, useTariffStaticOptions } from '@/features/billing/hooks'
import { TariffModel } from '@/features/billing/types'
import useStyles from './GeneralCard.styles'

interface IProps {
  data: TariffModel
  highlight?: boolean
  actionSections?: React.ReactNode
}

export const GeneralCard = ({ data, highlight = false, actionSections }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const { getCurrency } = useBilling()
  const { isAnnual } = useTariffStaticOptions()

  const { period, name, price, modules_text } = data

  const currencySymbol = getCurrency()?.symbol
  const periodLabel = isAnnual(period) ? t('month') : t('year')

  return (
    <>
      <Card
        withBorder
        className={cx(classes.card, {
          [`${classes.cardHighlight}`]: highlight,
        })}
        radius={'lg'}
        p={'lg'}
      >
        <div className={classes.header}>
          <Box>
            <Title order={3}>{name}</Title>
          </Box>

          {price && (
            <Box mt={'lg'}>
              <Title order={2}>
                {currencySymbol}
                {price}{' '}
                <Text component={'span'} transform={'lowercase'} fz="md" c="dimmed">
                  {`/`}
                  {periodLabel}
                </Text>
              </Title>
            </Box>
          )}
        </div>

        {actionSections && <Box mt={'lg'}>{actionSections}</Box>}

        <Box mt={'lg'}>
          <div className={classes.list} dangerouslySetInnerHTML={{ __html: modules_text }} />
        </Box>
      </Card>
    </>
  )
}
