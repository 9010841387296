import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PanelRoot } from '../panel'

export const BillingRoot = () => {
  return (
    <Routes>
      <Route index path={'/*'} element={<PanelRoot />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
