import { rootApi } from '@/store/api'
import { TariffModel } from '@/features/billing/types'
import { tempData } from './tempData'

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: ['BillingTariffs'] })

export const billingTariffsApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    billingTariffsList: builder.query<TariffModel[], any>({
      queryFn: (): { data: any[] } => ({ data: tempData }),
      // query: () => ({
      //   url: `tariffs`,
      // }),
      // transformResponse: (response: any) => {
      //   return response?.data
      // },
    }),
  }),
  overrideExisting: false,
})

export const { useBillingTariffsListQuery } = billingTariffsApi
