import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@mantine/core'
import { useSubscriptionStaticOptions, useTariffStaticOptions } from '@/features/billing/hooks'
import { DateUtils } from '@/utils'
import useStyles from './Table.styles'

export const SubscriptionOverview = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const subscription = {
    tariff: {
      name: 'Free',
      period: null,
    },
    status: 'active' as any,
    current_period_ends_at: null,
  }

  // const subscription = {
  //   tariff: {
  //     name: 'Premium',
  //     period: 'monthly' as any,
  //   },
  //   status: 'active' as any,
  //   current_period_ends_at: '2023-04-15',
  // }

  const { getPeriod } = useTariffStaticOptions()
  const { getStatus } = useSubscriptionStaticOptions()

  return (
    <div className={'overflow-x-auto'}>
      <Table className={classes.table}>
        <tbody>
          {subscription && (
            <>
              {subscription.tariff?.name && (
                <tr>
                  <th scope="row">{t('subscription_plan')}:</th>
                  <td>{subscription.tariff?.name}</td>
                </tr>
              )}

              <tr>
                <th scope="row">{t('subscription_status')}:</th>
                <td>{getStatus(subscription.status)?.label}</td>
              </tr>

              {subscription.tariff?.period && (
                <tr>
                  <th scope="row">{t('billed_period')}:</th>
                  <td>{getPeriod(subscription.tariff.period)?.label}</td>
                </tr>
              )}

              {subscription.current_period_ends_at && (
                <tr>
                  <th scope="row">{t('current_period_ends_at')}:</th>
                  <td>{DateUtils.formatDate(subscription.current_period_ends_at)}</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
    </div>
  )
}
