import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT } from '@/const/layout'

export default createStyles((theme, _params) => {
  return {
    main: {
      paddingTop: HEADER_HEIGHT,
      minHeight: '100vh',
      flex: 1,
      display: 'flex',
    },

    container: {
      flex: 1,
      display: 'flex',
      minWidth: 0,
    },
  }
})
