import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Navbar, ScrollArea, Space } from '@mantine/core'

interface IProps {
  children: React.ReactNode
}

export const NavbarContent = ({ children }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        <Space h={'xs'} />
        {children}
        <Space h={'xs'} />
      </Navbar.Section>
    </>
  )
}
