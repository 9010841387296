import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { authData } from '@/features/auth/store'
import { startsWith } from '@/libs/lodash'
import { Cookie } from '@/services'
import { RootState } from '@/store'

const BASE_API_URL = process.env.REACT_APP_API_URL
const BASE_URL = process.env.REACT_APP_BASE_URL

const xsrfCookieName = 'XSRF-TOKEN'
const xsrfHeaderName = 'X-XSRF-TOKEN'

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  credentials: 'include',
  paramsSerializer: (params: object) => {
    return qs.stringify(params, { encode: false })
  },
  prepareHeaders: (headers, { getState }) => {
    headers.set('Accept', 'application/json')

    const xsrfValue = Cookie.get(xsrfCookieName)
    if (xsrfValue) {
      headers.set(xsrfHeaderName, xsrfValue)
    }

    return headers
  },
})

export const baseQueryRequest: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  if (startsWith(args.url, 'auth')) {
    await baseQuery(
      {
        url: `${BASE_URL}sanctum/csrf-cookie`,
        method: 'GET',
      },
      api,
      extraOptions
    )
  }

  const result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status) {
    const { dispatch, getState } = api
    const isLoggedIn = (getState() as RootState).auth.data.isLoggedIn

    switch (result.error.status) {
      case 401:
        if (isLoggedIn) {
          dispatch(authData.cleanData())
        }
        break
      case 400:
        break
      case 419:
        dispatch(authData.cleanData())

        break
      default:
        break
    }
  }

  return result
}
