import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {},

  wrap: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },

  navs: {},
}))
