import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LandingLayout } from '@/components/Layouts'
import { Home } from '../home'
import { Pricing } from '../pricing'

export const LandingRoot = () => {
  return (
    <Routes>
      <Route element={<LandingLayout />}>
        <Route index element={<Home />} />

        <Route path={'pricing'} element={<Pricing />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
