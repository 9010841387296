import Cookies, { CookieGetOptions } from 'universal-cookie'

class CookieService {
  constructor() {}

  cookies = new Cookies()

  get = (name: string, options?: CookieGetOptions) => {
    return this.cookies.get(name, options)
  }
}

export const Cookie = new CookieService()
