import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { General as GeneralContainer } from '@/features/billing/components/BillingPanel'

export const General = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'billing'} />

      <GeneralContainer />
    </>
  )
}
