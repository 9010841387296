import { Global, useMantineTheme } from '@mantine/core'

export function GlobalStyles() {
  const theme = useMantineTheme()

  const dark = theme.colorScheme === 'dark'

  return (
    <Global
      styles={(theme) => ({
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },

        html: {
          position: 'relative',
          height: '100%',
        },

        body: {
          backgroundColor: dark ? theme.colors.dark[7] : theme.colors.gray[0],
        },
      })}
    />
  )
}
