import React from 'react'
import { useRoutes } from 'react-router-dom'
import { useAuth } from '@/features/auth/hooks'
import { useUser } from '@/features/user/hooks'
import { getRootRoutes } from '@/routes'

export const RootNavigator = () => {
  const { isLoggedIn } = useAuth()

  const { user } = useUser()

  return useRoutes(getRootRoutes(isLoggedIn, user))
}
