import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { SubscriptionPlans } from '@/features/billing/components/BillingPanel'

export const Subscriptions = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'subscription_plans'} />

      <SubscriptionPlans />
    </>
  )
}
