import React, { useMemo } from 'react'
import { useUser } from '@/features/user/hooks'
import { FormatUtils } from '@/utils'
import { NavBadge } from '../NavBadge'

interface IProps {
  isActive: boolean
}

export const ChatUnreadBadge = ({ isActive }: IProps) => {
  const { user } = useUser()

  // const count = user?.unread_messages || 0
  const count = 0

  const format = useMemo(() => {
    return FormatUtils.truncateNumber(count, 999)
  }, [count])

  return count ? <NavBadge isActive={isActive}>{format}</NavBadge> : null
}
