import { rootApi } from '@/store/api'
import { User } from '@/features/user/types'

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: ['User'] })

export const userApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    userData: builder.query<User, void>({
      query: () => ({
        url: `profile`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
})

export const { useUserDataQuery } = userApi
