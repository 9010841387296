import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLogout } from '@/features/auth/hooks'
import { useProfileDeleteMutation } from '@/features/profile/store'
import { useNotify } from '@/hooks'

export const useProfileDelete = () => {
  const { t } = useTranslation()

  const [profileDelete, { isLoading }] = useProfileDeleteMutation()

  const { showNotification } = useNotify()

  const { loading: logoutLoading, logout } = useLogout()

  const onRemove = async () => {
    try {
      await profileDelete().unwrap()

      showNotification({ type: 'success', message: t('account_deleted_successfully') })

      await logout()
    } catch (error: any) {
      const message = error?.data?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    loading: isLoading || logoutLoading,
    onRemove,
  }
}
