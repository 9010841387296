import { UploadProfileAvatarDTO } from '@/features/profile/api/dto'
import { userData } from '@/features/user/store'
import { profileApi } from '../../api/slice'

export const profileAvatarApi = profileApi.injectEndpoints({
  endpoints: (builder) => ({
    profileAvatarUpload: builder.mutation<any, UploadProfileAvatarDTO>({
      query: (arg) => {
        const formData = new FormData()
        formData.append('avatar', arg.avatar)

        return {
          url: 'profile/avatar',
          method: 'POST',
          body: formData,
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const data = await queryFulfilled
        dispatch(userData.updateData({ avatar: data.data }))
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    profileAvatarDelete: builder.mutation<void, void>({
      query: () => ({
        url: 'profile/avatar',
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const data = await queryFulfilled
        dispatch(userData.updateData({ avatar: null }))
      },
    }),
  }),
  overrideExisting: false,
})

export const { useProfileAvatarUploadMutation, useProfileAvatarDeleteMutation } = profileAvatarApi
