import React from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_SUBSCRIPTION_STATUS } from '@/features/billing/consts/subscription'
import { subscriptionHelper } from '@/features/billing/helpers'

export const useSubscriptionStaticOptions = () => {
  const { t } = useTranslation()

  const getStatus = (value: BILLING_SUBSCRIPTION_STATUS) => {
    return subscriptionHelper.status.getStatus(value)
  }

  return {
    getStatus,
  }
}
