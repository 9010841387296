import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProfileUpdateClientProfileMutation } from '@/features/profile/store'
import { useNotify } from '@/hooks'
import { EditForm } from './EditForm'

export const GeneralInfoEdit = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [updateProfile] = useProfileUpdateClientProfileMutation()

  const onSubmit = async (values: any) => {
    await updateProfile(values).unwrap()

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
  }

  return (
    <>
      <EditForm onSubmit={onSubmit} />
    </>
  )
}
