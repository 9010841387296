import * as Yup from 'yup'
import { PASSWORD_MIN_LENGTH } from '@/const/form'

export const validationSchema = Yup.object().shape({
  current_password: Yup.string().required().min(PASSWORD_MIN_LENGTH),
  password: Yup.string().required().min(PASSWORD_MIN_LENGTH),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], { key: 'field.error.password.no_match' })
    .min(PASSWORD_MIN_LENGTH),
})
