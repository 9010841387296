import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLogoutMutation } from '@/features/auth/store'
import { useNotify } from '@/hooks'

export const useLogout = () => {
  const { t } = useTranslation()

  const [logoutSubmit, { isLoading }] = useLogoutMutation()

  const { showNotification } = useNotify()

  const logout = async () => {
    try {
      await logoutSubmit().unwrap()
    } catch (error: any) {
      const message = error?.data?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    logout,
    loading: isLoading,
  }
}
