import { createSelector } from '@reduxjs/toolkit'
import { rootApi } from '@/store/api'
import { User } from '@/features/user/types'

export const staticApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    staticSelectsData: builder.query<User, void>({
      query: () => ({
        url: `selects`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
  }),
  overrideExisting: false,
})

export const { useStaticSelectsDataQuery } = staticApi

const selectStaticSelectsData = staticApi.endpoints.staticSelectsData.select()

export const selectStaticSelectsOptions = createSelector(
  [selectStaticSelectsData, (_, lang) => lang],
  (data: any, lang) => {
    return data?.[lang] || null
  }
)
