import React from 'react'
import { AuthLayout } from '@/components/Layouts'
import { ForgotPassword, Login, Register, ResetPassword } from '@/features/auth/routes'
import { LandingRoot } from '@/features/landing/routes'
import { AuthGuard } from '@/routes/components'

//config
export const getAuthRoutes = (isLoggedIn: boolean) => {
  return [
    {
      element: <AuthGuard isLoggedIn={isLoggedIn} />,
      children: [
        {
          index: !isLoggedIn,
          path: '/*',
          element: <LandingRoot />,
        },
        {
          element: <AuthLayout />,
          children: [
            {
              path: `login`,
              element: <Login />,
            },
            {
              path: `register`,
              element: <Register />,
            },
            {
              path: 'forgot-password',
              element: <ForgotPassword />,
            },
            {
              path: 'reset-password/:token',
              element: <ResetPassword />,
            },
          ],
        },
      ],
    },
  ]
}
