export const EN = {
  login: 'Login',
  sign_up: 'Sign Up',
  'message.error-fallback': 'Error occurred',
  refresh: 'Refresh',
  'email_verification.success': 'Verification passed successfully',
  welcome_back: 'Welcome back',
  dont_have_account: 'Don’t have an account',
  or: 'or',
  'field.error.required': 'This field is required',
  'field.error.invalid': 'This field is invalid',
  'field.error.password.length': 'The password must be at least {{count}} characters',
  'field.error.password.no_match': 'Passwords do not match',
  'field.error.min.length': 'The field must be at least 8 characters',
  error: 'Error',
  email: 'Email',
  password: 'Password',
  confirm_password: 'Confirm password',
  forgot_password: 'Forgot password',
  already_have_account: 'Already have an account',
  required: 'Required',
  reset_password: 'Reset password',
  remember_it: 'Remember it',
  reset: 'Reset',
  back_to: 'Back to',
  terms_and_conditions: 'Terms and conditions',
  logout: 'Logout',
  name: 'Name',
  'confirmation-email-sent': 'A confirmation email has been sent to your email',
  send_confirmation_email_to: 'Send a confirmation email to',
  success: 'Success',
  back: 'Back',
  next: 'Next',
  first_name: 'First name',
  last_name: 'Last name',
  no_options: 'No options',
  description: 'Description',
  start_date: 'Start date',
  end_date: 'End date',
  add: 'Add',
  saved_successfully: 'Saved successfully',
  phone: 'Phone',
  upload_photo: 'Upload photo',
  delete_photo: 'Delete photo',
  delete: 'Delete',
  'file.max_size.error': 'The file must not be greater than {{text}}',
  deleted_successfully: 'Deleted successfully',
  profile: 'Profile',
  yes: 'Yes',
  no: 'No',
  empty: 'Empty',
  not_filled: 'Not filled',
  no_data: 'No data',
  edit_profile: 'Edit profile',
  details: 'Details',
  cancel: 'Cancel',
  save: 'Save',
  skill_already_exist: 'Skill already exist',
  no_info: 'No info',
  from: 'From',
  to: 'To',
  'field.error.date.after-or-equal': 'The value must be a date after or equal to {{date}}.',
  today: 'Today',
  'field.error.date.after': 'The value must be a date after {{date}}.',
  status: 'Status',
  pending: 'Pending',
  active: 'Active',
  collapse: 'Collapse',
  expand: 'Expand',
  date: 'Date',
  confirmation: 'Confirmation',
  please_confirm: 'Please confirm',
  confirm: 'Confirm',
  sent_successfully: 'Sent successfully',
  action: 'Action',
  'field.error.time.after': 'The value must be a time after {{date}}.',
  removed_successfully: 'Removed successfully',
  settings: 'Settings',
  password_changed_successfully: 'Password changed successfully',
  change_password: 'Change password',
  current_password: 'Current password',
  new_password: 'New password',
  confirm_new_password: 'Confirm new password',
  you: 'You',
  attachments: 'Attachments',
  no_messages: 'No messages',
  unread_messages: 'Unread messages',
  write_a_message: 'Write a message',
  select_chat_to_start_messaging: 'Select a chat to start messaging',
  load_more: 'Load more',
  new_message: 'New message',
  download: 'Download',
  pay: 'Pay',
  invoice: 'Invoice',
  home: 'Home',
  register_terms_label: 'I have read and accept the',
  register_terms_text: 'terms of service and conditions',
  dashboard: 'Dashboard',
  chats: 'Chats',
  general_info: 'General info',
  deactivation: 'Deactivation',
  delete_account: 'Delete account',
  account_deleted_successfully: 'Account deleted successfully',
  dark: 'Dark',
  light: 'Light',
  'color-switch-to': 'Switch to {{ scheme }}',
  pricing: 'Pricing',
  start_now: 'Start now',
  all_rights_reserved: 'All rights reserved',
  monthly: 'Monthly',
  annually: 'Annually',
  inactive: 'Inactive',
  incomplete_payment: 'Incomplete payment',
  trialing: 'trialing',
  on_grace_period: 'On grace period',
  subscription_plans: 'Subscription plans',
  month: 'Month',
  year: 'Year',
  subscribe: 'Subscribe',
  billing: 'Billing',
  current_plan: 'Current plan',
  subscription_plan: 'Subscription plan',
  subscription_status: 'Subscription status',
  billed_period: 'Billed period',
  current_period_ends_at: 'Current period ends at',
  general: 'General',
}
