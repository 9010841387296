import { MantineThemeColorsOverride } from '@mantine/core'

export const overrideColors: MantineThemeColorsOverride = {
  secondary: [
    '#F8FCFC',
    '#EDF8F6',
    '#DBF0EE',
    '#C9E9E5',
    '#B3E0DA',
    '#9FD7D0',
    '#76C6BD',
    '#49B0A4',
    '#3D948A',
    '#2D6C65',
  ],
}
