import React from 'react'
import { AnchorProps, Anchor as MantineAnchor, useMantineTheme } from '@mantine/core'

interface IProps extends AnchorProps {
  children: React.ReactNode

  [x: string]: any
}

export const Anchor: React.FC<IProps> = ({ children, ...props }) => {
  const theme = useMantineTheme()

  return (
    <MantineAnchor color={theme.primaryColor} {...props}>
      {children}
    </MantineAnchor>
  )
}
