import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryRequest } from '@/libs'
import { RTKQueryUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const rootApi = createApi({
  reducerPath: 'rootApi',
  baseQuery: baseQueryRequest,
  tagTypes: [...cacher.defaultTags],
  endpoints: () => ({}),
})
