import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    wrapper: {
      position: 'relative',
      width: '100%',
    },

    inner: {
      position: 'relative',
      zIndex: 1,
    },
  }
})
