import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Alert, LoadingScreen } from '@/components/Elements'
import { useUserDataQuery } from '@/features/user/store'

export const PrivateLayout = () => {
  const { t } = useTranslation()

  const { error, isLoading, isFetching, isSuccess } = useUserDataQuery()

  const loading = isLoading || isFetching

  const prepared = isSuccess

  return loading ? (
    <LoadingScreen />
  ) : prepared ? (
    <>
      <Outlet />
    </>
  ) : error && 'data' in error ? (
    <Alert type={'error'} mt={'lg'} mx={'lg'}>
      {(error.data as any)?.message || t('error')}
    </Alert>
  ) : null
}
