import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT } from '@/const/layout'

export default createStyles((theme, _params, getRef) => {
  return {
    root: {
      padding: `${theme.spacing.lg} 0`,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
    },
  }
})
