import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Box,
  Burger,
  Container,
  Group,
  Header as MantineHeader,
  MediaQuery,
  useMantineTheme,
} from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import { Anchor, ButtonLink, ColorSchemeToggle, LogoIcon } from '@/components/Elements'
import { NavMenu, NavbarDrawer } from '@/components/Layouts/Elements'
import { HEADER_HEIGHT } from '@/const/layout'
import useStyles from './Header.styles'
import { GeneralLinks } from './Navbar'

export const Header = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()
  const theme = useMantineTheme()

  const [isNavbarDrawerOpened, toggleNavbarDrawerOpened] = useToggle([false, true])

  return (
    <>
      <MantineHeader className={classes.root} height={HEADER_HEIGHT} withBorder fixed zIndex={1}>
        <Container className={classes.header}>
          <Group grow className={'w-full flex-nowrap'}>
            <div>
              <Group className={'flex-nowrap'}>
                <MediaQuery largerThan="md" styles={{ display: 'none', height: 0 }}>
                  <Burger
                    opened={false}
                    onClick={() => toggleNavbarDrawerOpened()}
                    size="sm"
                    mr={'lg'}
                  />
                </MediaQuery>

                <Anchor component={Link} to={'/'}>
                  <LogoIcon size={'sm'} />
                </Anchor>
              </Group>
            </div>

            <MediaQuery smallerThan="md" styles={{ visibility: 'hidden' }}>
              <Box>
                <Box className={'flex justify-center'}>
                  <NavMenu>
                    <GeneralLinks onLinkClick={() => {}} />
                  </NavMenu>
                </Box>
              </Box>
            </MediaQuery>

            <div className={'flex justify-end'}>
              <Group spacing={'xl'}>
                <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
                  <Box>
                    <ColorSchemeToggle />
                  </Box>
                </MediaQuery>

                <ButtonLink to={'/login'} variant={'outline'}>
                  {t('login')}
                </ButtonLink>
              </Group>
            </div>
          </Group>
        </Container>
      </MantineHeader>

      <NavbarDrawer opened={isNavbarDrawerOpened} onClose={toggleNavbarDrawerOpened}>
        <GeneralLinks onLinkClick={toggleNavbarDrawerOpened} />
      </NavbarDrawer>
    </>
  )
}
