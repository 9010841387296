import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Text, Title } from '@mantine/core'
import { ButtonLink } from '@/components/Elements'
import useStyles from './HeroSection.styles'

export const HeroSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.section}>
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          <Title className={classes.title}>Lorem Ipsum</Title>

          <Container p={0} size={600}>
            <Text size="lg" color="dimmed" className={classes.description}>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s
            </Text>
          </Container>

          <div className={classes.controls}>
            <ButtonLink to={'/login'} size="lg" uppercase>
              {t('start_now')}
            </ButtonLink>
          </div>
        </div>
      </Container>
    </div>
  )
}
