import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT, LANDING_FOOTER_HEIGHT } from '@/const/layout'

export default createStyles((theme, _params, getRef) => {
  return {
    main: {
      paddingTop: HEADER_HEIGHT,
      minHeight: `calc(100vh - ${LANDING_FOOTER_HEIGHT}px)`,
      flex: 1,
      display: 'flex',
    },

    container: {
      flex: 1,
      display: 'flex',
      minWidth: 0,
      justifyContent: 'center',
      padding: 0,
    },
  }
})
