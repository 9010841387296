import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Alert, Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { useRegisterMutation } from '@/features/auth/store'
import { useLang } from '@/hooks'
import { FormValues, RegisterForm } from './RegisterForm'

export const RegisterContainer = () => {
  const { t } = useTranslation()

  const [register] = useRegisterMutation()

  const { lang } = useLang()

  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: FormValues) => {
    setSuccess(false)

    const params = {
      ...values,
      phone: values.phone.replace(/[^0-9]/g, ''),
      // role,
      // lang,
    }

    await register(params).unwrap()

    setSuccess(true)
  }

  return (
    <AuthCard title={t('sign_up')}>
      <div className={'flex'}>
        <Title order={6}>
          {t('already_have_account')}?{' '}
          <Anchor component={Link} to={'/login'}>
            {t('login')}
          </Anchor>
        </Title>
      </div>

      <Space h={'md'} />

      {success && (
        <Alert type={'success'} mb={'sm'}>
          {t('confirmation-email-sent')}!
        </Alert>
      )}

      <RegisterForm onSubmit={handleSubmit} />
    </AuthCard>
  )
}
