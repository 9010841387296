import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsCheckLg } from 'react-icons/bs'
import { Container, SimpleGrid, Text, ThemeIcon, Title } from '@mantine/core'
import useStyles from './FeaturesSection.styles'

export const FeaturesSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const data = [
    {
      title: 'Item 1',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    },
    {
      title: 'Item 2',
      description:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    },
    {
      title: 'Item 3',
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable",
    },
    {
      title: 'Item 4',
      description: 'Contrary to popular belief, Lorem Ipsum is not simply random text',
    },
  ]

  const items = data.map((item, i) => (
    <div className={classes.item} key={i}>
      <ThemeIcon variant="light" className={classes.itemIcon} size={40}>
        <BsCheckLg />
      </ThemeIcon>

      <div>
        <Text fw={700} fz="lg" className={classes.itemTitle}>
          {item.title}
        </Text>
        <Text c="dimmed">{item.description}</Text>
      </div>
    </div>
  ))

  return (
    <div className={classes.section}>
      <Container size={1200} className={classes.wrapper}>
        <Title className={classes.title}>Features</Title>

        <SimpleGrid
          cols={2}
          spacing={50}
          breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
          style={{ marginTop: 30 }}
        >
          {items}
        </SimpleGrid>
      </Container>
    </div>
  )
}
