import React from 'react'
import { ModelTypes } from '@/types'
import { Avatar, AvatarProps } from '../Avatar'

interface IProps extends AvatarProps {
  avatar: ModelTypes.AvatarType | undefined | null
  type?: 'full' | 'thumb'
  children?: React.ReactNode
}

export const AvatarProvider: React.FC<IProps> = ({
  avatar,
  type = 'thumb',
  children,
  ...props
}) => {
  const src = avatar ? (type === 'thumb' ? avatar?.thumb : type === 'full' ? avatar?.full : '') : ''

  return (
    <Avatar src={src} {...props}>
      {children}
    </Avatar>
  )
}
