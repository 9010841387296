import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  children?: React.ReactNode
  variant?: 'empty' | 'fill' | 'data' | 'info'
}

export const NoDataTextMessage = ({ children, variant = 'empty' }: IProps) => {
  const { t } = useTranslation()

  const message =
    variant === 'empty'
      ? t('empty')
      : variant === 'fill'
      ? t('not_filled')
      : variant === 'data'
      ? t('no_data')
      : variant === 'info'
      ? t('no_info')
      : ''

  return <React.Fragment>{children ? children : message}</React.Fragment>
}
