import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './HomeContent.styles'
import { FeaturesSection, HeroSection } from './Sections'

export const HomeContent = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={'w-full'}>
      <HeroSection />

      <FeaturesSection />
    </div>
  )
}
