import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from '@mantine/core'
import { Footer } from './Footer'
import { Header } from './Header'
import useStyles from './LandingLayout.styles'

export const LandingLayout = () => {
  const { classes } = useStyles()

  return (
    <div>
      <Header />

      <main className={classes.main}>
        <Container fluid className={classes.container}>
          <Outlet />
        </Container>
      </main>

      <Footer />
    </div>
  )
}
