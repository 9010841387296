import * as Yup from 'yup'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required(),
  last_name: Yup.string().nullable().required(),
  phone: Yup.string()
    .test('phone', { key: 'field.error.invalid' }, ValidationsUtils.phoneFormat)
    .required(),
})
