import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useProfileAvatarDeleteMutation,
  useProfileAvatarUploadMutation,
} from '@/features/profile/store'
import { useNotify } from '@/hooks'

export const useAvatarManager = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [avatarUpload, { isLoading: uploading }] = useProfileAvatarUploadMutation()

  const onUpload = async (file: File) => {
    try {
      const data = {
        avatar: file,
      }

      await avatarUpload(data).unwrap()

      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })
    } catch (error: any) {
      const message = error?.data?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  const [avatarRemove, { isLoading: removing }] = useProfileAvatarDeleteMutation()

  const onRemove = async () => {
    try {
      await avatarRemove().unwrap()

      showNotification({
        type: 'success',
        message: t('deleted_successfully'),
      })
    } catch (error: any) {
      const message = error?.data?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    uploading,
    onUpload,
    removing,
    onRemove,
  }
}
