import React from 'react'
import { useTranslation } from 'react-i18next'
import { SubscriptionOverview } from './SubscriptionOverview'

export const General = () => {
  const { t } = useTranslation()

  return (
    <>
      <SubscriptionOverview />
    </>
  )
}
