import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const dark = theme.colorScheme === 'dark'

  return {
    section: {
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      backgroundColor: dark ? theme.colors.dark[7] : theme.white,
      flexDirection: 'column',
    },

    wrapper: {
      position: 'relative',
      paddingTop: 50,
      paddingBottom: 50,
      width: '100%',
    },

    inner: {
      position: 'relative',
      zIndex: 1,
    },
  }
})
