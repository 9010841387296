import React, { useReducer } from 'react'
import { UseFormSetError } from 'react-hook-form'
import { FormTypes } from '@/types'
import { ValidationsUtils } from '@/utils'

interface SubmitState {
  error: any
}

const initialState: SubmitState = {
  error: null,
}

type ReducerAction = {
  type: string
  payload?: any
}

const reducer = (state: SubmitState, action: ReducerAction) => {
  switch (action.type) {
    case 'error':
      return {
        ...state,
        error: action.payload,
      }
    case 'reset':
      return initialState
    default:
      return state
  }
}

interface IProps {
  submit: (...args: any[]) => Promise<void>
  setError: UseFormSetError<any>
}

export const useFormSubmit = ({ submit, setError }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const onSubmit = async (...args: any[]) => {
    resetState()

    try {
      await submit(...args)
    } catch (error: any) {
      dispatch({ type: 'error', payload: error })

      if (setError) {
        const serverError = error?.data as FormTypes.ValidationErrors
        ValidationsUtils.setServerSideErrors(serverError?.errors, setError)
      }

      throw error
    }
  }

  const resetState = () => {
    dispatch({ type: 'reset' })
  }

  return {
    ...state,
    onSubmit,
    resetState,
  }
}
