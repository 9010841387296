import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkType } from '@/components/Layouts/Elements'

interface IProps {
  onLinkClick: () => void
}

export const GeneralLinks = ({ onLinkClick }: IProps) => {
  const { t } = useTranslation()

  const links: Array<NavLinkType> = [
    {
      path: '/',
      label: t('home'),
    },
    {
      path: '/pricing',
      label: t('pricing'),
    },
  ]

  return (
    <>
      {links.map((item) => {
        return <NavLink key={item.path} onClick={onLinkClick} {...item} />
      })}
    </>
  )
}
