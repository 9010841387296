import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_TARIFF_ALIAS } from '@/features/billing/consts/tariff'
import { useBillingTariffsListQuery } from '@/features/billing/store'
import { TariffModel } from '@/features/billing/types'

interface IProps {
  options?: {
    skip: boolean
  }
}

const defaultOptions = {
  skip: true,
}

export const useTariffsList = ({ options }: IProps) => {
  const { t } = useTranslation()

  options = {
    ...defaultOptions,
    ...options,
  }

  const { isLoading, isFetching, error, data, isSuccess } = useBillingTariffsListQuery({}, options)

  const tariffs = data || []

  const tariffsDataMap = useMemo(() => {
    return tariffs.reduce((acc: any, curr) => {
      const { alias } = curr

      return acc.set(alias, curr)
    }, new Map())
  }, [tariffs])

  const onFetch = () => {
    // trigger()
  }

  const onClean = () => {}

  const getTariffDataByAlias = (alias: BILLING_TARIFF_ALIAS): TariffModel | undefined => {
    return tariffsDataMap.get(alias)
  }

  return {
    list: tariffs,
    error,
    loading: isLoading || isFetching,
    fetched: isSuccess,
    onFetch,
    onClean,
    getTariffDataByAlias,
  }
}
