import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { useLoginMutation, useVerifyEmailMutation } from '@/features/auth/store'
import { FormValues, LoginForm } from './LoginForm'

export const LoginContainer = () => {
  const { t } = useTranslation()

  const [login] = useLoginMutation()

  const [verifyEmail] = useVerifyEmailMutation()

  const handleSubmit = async (values: FormValues) => {
    await login(values).unwrap()
  }

  const onVerifyEmail = async (email: string) => {
    await verifyEmail({ email })
  }

  return (
    <AuthCard title={t('login')}>
      <div className={'flex'}>
        <Title order={6}>
          {t('dont_have_account')}?{' '}
          <Anchor component={Link} to={'/register'}>
            {t('sign_up')}
          </Anchor>
        </Title>
      </div>

      <Space h={'md'} />

      <LoginForm onSubmit={handleSubmit} onVerifyEmail={onVerifyEmail} />
    </AuthCard>
  )
}
