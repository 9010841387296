import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { GeneralCard } from '@/components/Elements'
import { PageContent, PageHeader } from '@/components/Layouts'
import { Tabs } from './Tabs'

export const BillingPanelShell = () => {
  const { t } = useTranslation()

  return (
    <PageContent>
      <PageHeader title={t('billing')} />

      <Tabs />

      <GeneralCard className={'rounded-t-none grow'}>
        <Outlet />
      </GeneralCard>
    </PageContent>
  )
}
