type ErrorType =
  | {
      message:
        | string
        | {
            key: string
            values?: { [x: string]: any }
          }
    }
  | string

type TranslatePropsType = {
  t: any
  translateParams: object | undefined
}

const getErrorMessage = (error: ErrorType, translateProps: TranslatePropsType) => {
  if (!error) {
    return null
  }

  if (typeof error === 'string') {
    return error
  } else if (error.message) {
    const { message } = error

    if (typeof message === 'string') {
      return message
    } else {
      const { key, values } = message

      const { t } = translateProps
      return t(key, values)
    }
  } else {
    return null
  }
}

export { getErrorMessage }
