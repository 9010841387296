import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { NormalizeUtils } from '@/utils'

export type GeneralFormSectionValues = {
  first_name: string
  last_name: string
  email: string
  phone: string
  // lang: string
}

export const GeneralFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<GeneralFormSectionValues>()

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'first_name'}
            id={'first_name'}
            label={t('first_name')}
            placeholder={t('first_name')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'last_name'}
            id={'last_name'}
            label={t('last_name')}
            placeholder={t('last_name')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
            mb={'md'}
            readOnly
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'phone'}
            id={'phone'}
            label={t('phone')}
            placeholder={t('phone')}
            mb={'md'}
            normalize={NormalizeUtils.phone}
          />
        </Grid.Col>
      </Grid>

      {/*<Grid>*/}
      {/*  <Grid.Col md={6}>*/}
      {/*    <LangPickerController control={control} name={'lang'} label={t('language')} mb={'md'} />*/}
      {/*  </Grid.Col>*/}
      {/*</Grid>*/}
    </>
  )
}
