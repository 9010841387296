import React from 'react'
import { useTranslation } from 'react-i18next'
import { billingHelper } from '@/features/billing/helpers'

export const useBilling = () => {
  const { t } = useTranslation()

  const getCurrency = () => {
    return billingHelper.general.getCurrency()
  }

  return {
    getCurrency,
  }
}
