import _ from 'lodash'
import { BILLING_SUBSCRIPTION_STATUS } from '@/features/billing/consts/subscription'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: BILLING_SUBSCRIPTION_STATUS
}

export const getStatuses = () => {
  return [
    {
      label: i18n.t('active'),
      value: BILLING_SUBSCRIPTION_STATUS.ACTIVE,
    },
    {
      label: i18n.t('inactive'),
      value: BILLING_SUBSCRIPTION_STATUS.INACTIVE,
    },
    {
      label: i18n.t('incomplete_payment'),
      value: BILLING_SUBSCRIPTION_STATUS.INCOMPLETE_PAYMENT,
    },
    {
      label: i18n.t('trialing'),
      value: BILLING_SUBSCRIPTION_STATUS.TRIALING,
    },
    {
      label: i18n.t('on_grace_period'),
      value: BILLING_SUBSCRIPTION_STATUS.ON_GRACE_PERIOD,
    },
  ]
}

export const getStatus = (value: OptionType | string): OptionType | any => {
  const options = getStatuses()
  return _.find(options, { value })
}
