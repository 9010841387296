import React from 'react'
import { ROLE, UserModel } from '@/features/users/types'

export const useUsersModel = () => {
  const isClient = (user: UserModel) => {
    return user?.role === ROLE.Client
  }

  const getName = (user: UserModel | null | undefined) => {
    if (user) {
      let result = user?.first_name

      if (user?.last_name) {
        result = result ? `${result} ${user?.last_name}` : user?.last_name
      }

      return result
    }
    return null
  }

  return {
    isClient,
    getName,
  }
}
