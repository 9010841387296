import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { STATIC_SELECT, useStaticSelects } from '@/features/static/hooks'
import { ModelTypes } from '@/types'

const mapStaticOptions = (options: Array<{ id: number; name: string }>) => {
  return options.map((item) => ({
    ...item,
    label: item.name,
    value: item.id.toString(),
  }))
}

export const useSelectOptions = () => {
  const { t } = useTranslation()

  const staticSelects = useStaticSelects()

  const getStaticSelectOptions = (key: STATIC_SELECT) => {
    return mapStaticOptions(staticSelects.getSelectOptions(key))
  }

  const getCountries = () => {
    return getStaticSelectOptions(STATIC_SELECT.COUNTRIES)
  }

  const getCountry = (value: string): ModelTypes.CountryType | any => {
    const countries = getCountries()

    return _.find(countries, { value })
  }

  return {
    getCountries,
    getCountry,
  }
}
