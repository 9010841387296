import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Anchor } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  PasswordInputController,
  TextInputController,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { useFormSubmit } from '@/hooks'
import { NormalizeUtils, ValidationsUtils } from '@/utils'

export type FormValues = {
  first_name: string
  last_name: string
  email: string
  phone: string
  password: string
  password_confirmation: string
  accept: boolean
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const RegisterForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      accept: false,
    },
    resolver: yupResolver(
      Yup.object().shape({
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
        email: Yup.string().email().required(),
        phone: Yup.string()
          .test('phone', { key: 'field.error.invalid' }, ValidationsUtils.phoneFormat)
          .required(),
        password: Yup.string().required().min(PASSWORD_MIN_LENGTH),
        password_confirmation: Yup.string()
          .required()
          .oneOf([Yup.ref('password'), null], { key: 'field.error.password.no_match' })
          .min(PASSWORD_MIN_LENGTH),
        accept: Yup.bool().oneOf([true], 'required'),
      })
    ),
  })

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  const watchAccept = watch('accept')

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <TextInputController
          control={control}
          name={'first_name'}
          id={'first_name'}
          label={t('first_name')}
          placeholder={t('first_name')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'last_name'}
          id={'last_name'}
          label={t('last_name')}
          placeholder={t('last_name')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          label={t('email')}
          placeholder={t('email')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'phone'}
          id={'phone'}
          label={t('phone')}
          placeholder={t('phone')}
          mb={'md'}
          normalize={NormalizeUtils.phone}
        />

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <PasswordInputController
          control={control}
          name={'password_confirmation'}
          id={'password_confirmation'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <CheckboxFieldController
          control={control}
          name={'accept'}
          id={'accept'}
          label={
            <>
              {t('register_terms_label')}{' '}
              <Anchor
                component={Link}
                to={'/terms'}
                className={'font-bold'}
                state={{ from: '/register' }}
              >
                {t('register_terms_text')}
              </Anchor>
            </>
          }
          mb={'md'}
        />

        <Button mt={'lg'} fullWidth type={'submit'} disabled={!watchAccept} loading={isSubmitting}>
          {t('sign_up')}
        </Button>
      </form>
    </>
  )
}
