import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BillingPanelShell } from '@/features/billing/components/BillingPanel/Layouts'
import { General, Subscriptions } from '../panel'

export const PanelRoot = () => {
  return (
    <Routes>
      <Route element={<BillingPanelShell />}>
        <Route index element={<General />} />
        <Route path={'subscriptions'} element={<Subscriptions />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
