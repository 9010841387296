import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Container, Flex, SimpleGrid, Title } from '@mantine/core'
import { ButtonLink, DataShower, NoDataTextMessage } from '@/components/Elements'
import { TariffsPeriodSwitch } from '@/features/billing/components/Elements'
import { GeneralCard } from '@/features/billing/components/Tariff/Tariffs/Cards'
import {
  useTariffStaticOptions,
  useTariffsList,
  useTariffsPeriodGrid,
} from '@/features/billing/hooks'
import { TariffModel } from '@/features/billing/types'
import useStyles from './SubscriptionPlans.styles'

export const SubscriptionPlans = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { list, fetched, loading, error, onFetch, onClean } = useTariffsList({
    options: { skip: false },
  })

  const { isPremium } = useTariffStaticOptions()

  useEffect(() => {
    onFetch()

    return () => {
      onClean()
    }
  }, [])

  const { period, onPeriodChange, data: tariffs } = useTariffsPeriodGrid({ tariffs: list })

  const renderTariffAction = (tariff: TariffModel) => {
    const current = !isPremium(tariff.alias)

    return (
      <ButtonLink
        fullWidth
        to={''}
        variant={isPremium(tariff.alias) ? 'filled' : 'outline'}
        disabled={current}
      >
        {current ? t('current_plan') : t('subscribe')}
      </ButtonLink>
    )
  }

  return (
    <div>
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          <Container py={20} size={800}>
            <DataShower isLoading={loading} isFetched={fetched} isFailed={!!error} error={error}>
              <Flex direction="column" align="center">
                <Box mb={'xl'}>
                  <TariffsPeriodSwitch value={period} onChange={onPeriodChange} />
                </Box>

                {tariffs.length > 0 ? (
                  <SimpleGrid
                    className={'w-full'}
                    cols={tariffs.length}
                    spacing={'xl'}
                    breakpoints={[{ maxWidth: 550, cols: 1 }]}
                  >
                    {tariffs.map((item) => (
                      <React.Fragment key={item.id}>
                        <GeneralCard
                          data={item}
                          highlight={isPremium(item.alias)}
                          actionSections={renderTariffAction(item)}
                        />
                      </React.Fragment>
                    ))}
                  </SimpleGrid>
                ) : (
                  <NoDataTextMessage variant={'data'} />
                )}
              </Flex>
            </DataShower>
          </Container>
        </div>
      </Container>
    </div>
  )
}
