export const tempData = [
  {
    id: 1,
    name: 'Free',
    alias: 'free',
    period: 'monthly',
    modules_text: '<ul><li>Option</li><li>Option</li></ul>',
    price: null,
    price_monthly: null,
  },
  {
    id: 2,
    name: 'Premium',
    alias: 'premium',
    period: 'monthly',
    modules_text: '<ul><li>Option</li><li>Option</li><li>Option</li><li>Option</li></ul>',
    price: '29',
    price_monthly: '29',
  },
  {
    id: 3,
    name: 'Premium',
    alias: 'premium',
    period: 'annually',
    modules_text: '<ul><li>Option</li><li>Option</li><li>Option</li><li>Option</li></ul>',
    price: '199',
    price_monthly: '199',
  },
]
