import React from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentedControl } from '@/components/FormElements'
import { BILLING_TARIFF_PERIOD } from '@/features/billing/consts/tariff'
import { useTariffStaticOptions } from '@/features/billing/hooks'

interface IProps {
  value: BILLING_TARIFF_PERIOD | string
  onChange: (value: string) => void
}

export const TariffsPeriodSwitch = ({ value, onChange }: IProps) => {
  const { t } = useTranslation()

  const { getPeriods } = useTariffStaticOptions()

  return (
    <>
      <SegmentedControl
        color={'primary'}
        data={getPeriods()}
        value={value}
        onChange={onChange}
        meta={{}}
      />
    </>
  )
}
