import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const dark = theme.colorScheme === 'dark'

  return {
    table: {
      ['th']: {
        borderTop: `1px solid ${dark ? theme.colors.dark[4] : theme.colors.gray[3]}`,
        textAlign: 'left',
        width: '30%',
        whiteSpace: 'nowrap',
      },

      ['tr:first-of-type th']: {
        border: 'none',
      },
    },
  }
})
