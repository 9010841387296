import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Chats } from '../chats'

export const ChatsRoot = () => {
  return (
    <Routes>
      <Route index element={<Chats />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
