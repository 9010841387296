import React from 'react'
import i18n from '@/i18n'
import { LOCALE } from '@/services'

export const useLang = () => {
  const lang = i18n.language

  const changeLang = async (code: string) => {
    if (code !== lang) {
      await LOCALE.changeLang(code)
    }
  }

  return {
    lang,
    changeLang,
  }
}
