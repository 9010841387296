import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const dark = theme.colorScheme === 'dark'

  return {
    section: {
      backgroundColor: dark ? theme.colors.dark[7] : theme.white,
    },

    wrapper: {
      paddingTop: 80,
      paddingBottom: 50,
    },

    item: {
      display: 'flex',
    },

    itemIcon: {
      padding: theme.spacing.xs,
      marginRight: theme.spacing.md,
    },

    itemTitle: {
      marginBottom: `calc(${theme.spacing.xs} / 2)`,
    },

    title: {
      lineHeight: 1,
      textAlign: 'center',
      marginTop: theme.spacing.xl,
    },

    description: {
      textAlign: 'center',
      marginTop: theme.spacing.xs,
    },
  }
})
